vue
<template>
  <div>
    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
        <p class="title-bold">Psicologia</p>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12 p-d-flex p-jc-center">
          <img class="img-fluid" alt="psicologia ayuda" src="../../assets/psicologiaAdultos.jpg" />
      </div>

      <div class="p-col-10 p-offset-1 p-d-flex p-jc-center">
        <Card styleClass="p-card-shadow">
          <template #content>

            <p>
              En ciertos momentos de nuestra vida, nos encontramos con situaciones que escapan a nuestro control y puede
              resultarnos difícil saber cómo afrontarlas. Sin embargo, es importante recordar que siempre hay una solución
              y que podemos superar cualquier obstáculo que se presente en nuestro camino.
            </p>
            <p>
              En nuestro centro, entendemos que cada persona es única y enfrenta desafíos diferentes. Nuestro objetivo es
              brindarte el apoyo necesario para que puedas recuperar el control de tu vida y encontrar la felicidad y el
              bienestar que mereces.
            </p>
            <p class="font-bold">¿Cuándo es recomendable acudir a un psicólogo?</p>
            <p>
              Te recomendamos acudir a un psicólogo cuando sientas que tu vida cotidiana se ve afectada y te resulte
              difícil realizar actividades que antes solías hacer con facilidad. También es válido buscar ayuda cuando
              deseas crecer a nivel personal y encontrar una mayor motivación en tu vida.
            </p>
            <p>
              Nuestro equipo de profesionales está aquí para ayudarte en cualquier momento. Si experimentas ansiedad,
              estrés, miedos, tristeza, problemas de pareja o adicciones, no dudes en concertar una cita con nosotros.
              Estaremos encantados de acompañarte en tu proceso de cambio y ayudarte a encontrar el equilibrio y la
              plenitud que buscas.
            </p>
            <p>
              Recuerda que siempre hay una luz al final del túnel y que juntos podemos superar cualquier desafío. ¡Te
              esperamos con los brazos abiertos en nuestro centro!
            </p>

          </template>

          <template #footer>
            <span>Para más información no dude en ponerse en contacto con nuestro
              Centro médico al 637 857 384.</span>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from 'primevue/card';
export default {
  name: 'PsicologiaVue',
  data() {
    return {
      // Propiedades de datos del componente
    };
  },
  components: {
    Card
  },
  methods: {
    // Métodos del componente
  },
  computed: {
    // Propiedades computadas del componente
  },
  created() {
    // Lógica que se ejecuta cuando el componente se crea
  },
  mounted() {
    // Lógica que se ejecuta cuando el componente se monta en el DOM
  },
  destroyed() {
    // Lógica que se ejecuta cuando el componente se destruye
  },
};
</script>

<style scoped>
.title-bold {
  font-size: 30px; 
  font-weight: bold
}
.img-fluid {
  width: 100%;
  height: auto;
}

@media (min-width: 576px) {
  .img-fluid {
    max-width: 50%;
    height: auto;
  }
}

</style>